// import Vue from "vue";
import Keycloak from "../../node_modules/keycloak-js/lib/keycloak";

const keycloak = new Keycloak({
  url: "https://sso.homesvithome.se/auth",  // ✅ Must end in /auth
  realm: "hsh",
  clientId: "frontend1",
});

export { keycloak };

export default {
  install(Vue) {
    Vue.$keycloak = keycloak;
    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return keycloak;
        },
      },
    });
  },
};

/*
const _keycloak = new Keycloak({
  realm: "hsh",
  clientId: "frontend1",
  issuer: "https://sso.homesvithome.se/realms/hsh", // updated format
});

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak;

    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return _keycloak;
        },
      },
    });

    console.log("Install Plugin Keycloak");

    _keycloak
      .init({
        onLoad: "login-required",
        flow: "standard",
        pkceMethod: "S256",
      })
      .then((authenticated) => {
        if (authenticated) {
          console.log("Keycloak initialized");
        } else {
          console.warn("Not authenticated");
        }
      });

    // Token Refresh
    setInterval(() => {
      _keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.info("Token refreshed " + refreshed);
          } else {
            console.debug("Token still valid");
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 30000);
  },
};

Vue.use(Plugin);

export default Plugin;
*/

/*
import Vue from "vue";
import Keycloak from "keycloak-js";

// Loginsidans URL
// https://sso.homesvithome.se/auth/realms/hsh/protocol/openid-connect/auth?client_id=frontend1&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Ffe-vue%2F&state=e8514cfb-426a-4013-8341-fb03c88f2d2a&response_mode=fragment&response_type=code&scope=openid&nonce=39e37a9b-0d35-425d-b131-1090529d3cf1&code_challenge=vDSZFl8RVegimlQ4snBKaitfl3NoKabrOxyXqYKG3TY&code_challenge_method=S256

const options = {
  url:  "https://sso.homesvithome.se/auth",  // "http://192.168.10.20:8080/realms/hsh", //
  realm: "hsh",
  clientId: "frontend1",
  onLoad: "login-required",
  flow: "standard",
  scope: "form-read",
  enableLogging: true,
  pkceMethod: "S256",
};

const _keycloak = Keycloak(options);

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak;
  },
};

Plugin.install = (Vue) => {
  Vue.$keycloak = _keycloak;

  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });

  console.log("Install Plugin Keycloak" )
   //Token Refresh
   setInterval(() => {
    _keycloak
      .updateToken(70)
      .then((refreshed) => {
        if (refreshed) {
          Vue.$log.info("Token refreshed " + refreshed);
          //Vue.$store.commit("setKeycloakToken", Keycloak.tokenParsed);
        } else {
          // Vue.$log.warn("Token not refreshed, valid for " +
          //     Math.round(
          //       _keycloak.tokenParsed.exp +
          //       _keycloak.timeSkew -
          //         new Date().getTime() / 1000
          //     ) +
          //     " seconds"
          // );
        }
      })
      .catch(() => {
        Vue.$log.error("Failed to refresh token");
      });
  }, 30000);
};

Vue.use(Plugin);

export default Plugin;
*/

/*
import Vue from "vue";
import App from "./App.vue";
import Keycloak from "keycloak-js";
import VueLogger from "vuejs-logger/dist";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import templates from "./templates.js";

Vue.config.productionTip = false;
Vue.use(VueLogger);

// Make globally accessible
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
// Vue.prototype.$moment = moment
Vue.prototype.$templates = templates;
Vue.prototype.$vue = Vue;

let initOptions = {
  url: "https://sso.homesvithome.se/auth",
  realm: "hsh",
  clientId: "frontend1",
  onLoad: "login-required",
  flow: "standard",
  scope: "form-read",
  enableLogging: true,
  pkceMethod: "S256",
};

let keycloak = Keycloak(initOptions);

keycloak
  .init(initOptions)
  .then((auth) => {
    Vue.$log.info("Entering then block");
    if (!auth) {
      Vue.$log.info("Entering !auth block");
      window.location.reload();
    } else {
      Vue.$log.info("Authenticated");
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App, { props: { keycloak: keycloak } }),
      }).$mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            Vue.$log.info("Token refreshed" + refreshed);
            Vue.$store.commit("setKeycloakToken", keycloak.tokenParsed);
          } else {
            Vue.$log.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          Vue.$log.error("Failed to refresh token");
        });
    }, 30000);
  })
  .catch((e) => {
    Vue.$log.error("Authenticated Failed");
    Vue.$log.error(e);
  });
*/