
import Vue from "vue";
import axios from "axios";
import _ from "lodash";
import JSZip from "jszip";
//import store from '../store'
import { keycloak } from "@/plugins/authentication";  // ✅ import Keycloak instance

const apiHshClient = axios.create({
  baseURL: process.env.VUE_APP_API || "https://api.homesvithome.se/v1.2/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// 🔐 Inject token on every request
apiHshClient.interceptors.request.use(async (config) => {
  // Ensure keycloak is authenticated and token is valid
  if (keycloak.authenticated) {
    try {
      await keycloak.updateToken(5);  // refresh if token expires in < 5 sec
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    } catch (e) {
      console.error("Token refresh failed", e);
    }
  }
  return config;
});
/*
const apiHshClient = axios.create({
  baseURL: process.env.VUE_APP_API || "https://api.homesvithome.se/v1.2/",
  // baseURL: "http://localhost:8080/v1.2/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiHshClient.interceptors.request.use(async (config) => {
  // const token = await updateToken();
  // console.log("Axios Hsh function ", store.state.keycloakToken);
  // config.headers.common["Authorization"] = `Bearer ${store.state.keycloakToken}`;
  config.headers.common["Authorization"] = `Bearer ${Vue.$keycloak.token}`;
  //console.log("intercept...",config)
  return config;
});

apiHshClient.interceptors.response.use(
  (response) => {
    // Do something with the response
    return response;
  },
  (error) => {
    // Handle the error
    if (error.response.status === 401) {
      // The user is unauthorized
      window.location = "/";
    }
    return Promise.reject(error);
  }
);
*/

const hshApi = {
  // Zip file
  async getZipMediaFile() {
    const p = await apiHshClient
      .get("/media/content", { responseType: "blob" })
      .then((response) => {
        const zip = new JSZip();
        return zip.loadAsync(response.data);
      })
      .then((zip) => {
        const promises = [];
        Object.keys(zip.files).forEach((filename) => {
          promises.push(
            zip.files[filename].async("blob").then((blob) => {
              return {
                filename: filename,
                url: URL.createObjectURL(blob),
              };
            })
          );
        });
        return Promise.all(promises);
      })
      .then((urls) => {
        console.log("ZIP urls ", urls);
        return urls;
      })
      // .then((urls) => {
      //   console.log("ZIP urls",urls)
      //   return urls
      //   // use urls here
      // })
      .catch((error) => {
        console.log(error);
      });
    return p;
  },
  // =================================================
  // Topics
  // =================================================
  async getTopics() {
    const response = await apiHshClient.get("/topics");
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async newTopics(topic) {
    delete topic.id;
    const response = await apiHshClient.post("/topics", topic);
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async updateTopics(topic) {
    const response = await apiHshClient.put(`/topics/${topic.id}`, topic);
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async deleteTopics(id) {
    const response = await apiHshClient.delete(`/topics/${id}`);
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  // =================================================
  // Media
  // =================================================
  async getAudioMedia(id) {
    const { data } = await apiHshClient.get(`/media/${id}/content`, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "audio/wav",
      },
    });
    const blob = new Blob([data], {
      type: "audio/wav",
    });

    const url = URL.createObjectURL(blob);
    var audio = new Audio(url); // path to file
    // console.log(`getAudioMedia nr ${id}...`,url)
    //audio.play();
    return audio;
  },

  postMediaFile(file, infoObj, onUploadProgress) {
    let formData = new FormData();
    formData.append("description", infoObj.description);
    formData.append("lang", infoObj.lang);
    formData.append("name", infoObj.name);
    formData.append("topic_id", infoObj.topic_id);
    formData.append("content", file);
    console.log("postImageMedia", formData);
    return apiHshClient.post("/media", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  },

  async getImgMedia(id) {
    const { data } = await apiHshClient.get(`/media/${id}/content`, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "image/png",
      },
    });
    const blob = new Blob([data], {
      type: "image/png",
    });

    const url = URL.createObjectURL(blob);
    // var image = url; // path to file
    // console.log("try play audio now...",url)
    // audio.play();
    return url;
  },
  async getMediaList() {
    const response = await apiHshClient.get("/media");
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },

  // =================================================
  // Forms
  // =================================================
  async getForms(formid) {
    // const response = await apiHshClient.get(`/forms?servey=${belongToSurveyId}`);
    console.log(
      `hshapi getForms: Fetch forms unused SURVEY ID:${formid}`,
      Vue.$keycloak.token.length
    );
    let response;
    if (formid < 0) {
      response = await apiHshClient.get(`/forms`);
    } else {
      response = await apiHshClient.get(`/forms/${formid}`);
    }
    if (response.data.results) {
      console.log("getForms return", response.data.results);
      return response.data.results;
    }
    console.log("getForms return", response.data);
    return response.data;
  },
  async createForm(form) {
    delete form.id;
    const response = await apiHshClient.post("/forms", form);
    return response.data;
  },
  async updateForm(form) {
    const response = await apiHshClient.put(`/forms/${form.id}`, form);
    return response.data;
  },
  async deleteForm(form) {
    const response = await apiHshClient.delete(`/forms/${form.id}`);
    return response.data;
  },

  // =================================================
  // Questions
  // =================================================
  async getQuestion(id) {
    const response = await apiHshClient.get(`/questions/${id}`);
    if (response.data.results) {
      console.log("getQuestions/id data.result", response.data.results);
      return response.data.results;
    }
    return response.data;
  },
  async getQuestions() {
    const response = await apiHshClient.get("/questions");
    if (response.data.results) {
      console.log("getQuestions data.result", response.data.results);
      return response.data.results;
    }
    return response.data;
  },
  async createQuestion(quest) {
    delete quest.id;
    const response = await apiHshClient.post("/questions", quest);
    return response.data;
  },
  async updateQuestion(quest) {
    console.log("updateQuestion", quest);
    const response = await apiHshClient.put(`/questions/${quest.id}`, quest);
    return response.data;
  },
  async deleteQuestion(quest) {
    const response = await apiHshClient.delete(`/questions/${quest.id}`);
    return response.data;
  },
  // =================================================
  // Surveys
  // =================================================
  async getSurveys() {
    const response = await apiHshClient.get("/surveys");
    return response.data;
  },
  async createSurvey(survey) {
    delete survey.id;
    const response = await apiHshClient.post("/surveys", survey);
    return response.data;
  },
  async updateSurvey(survey) {
    const response = await apiHshClient.put(`/surveys/${survey.id}`, survey);
    return response.data;
  },
  async deleteSurvey(survey) {
    const response = await apiHshClient.delete(`/surveys/${survey.id}`);
    return response.data;
  },
  async startSurvey(startSurveyObject, surveyId) {
    const response = await apiHshClient.post(
      `/surveys/instances/${surveyId}/start`,
      startSurveyObject
    );
    return response.data;
  },
  async startRepeateSurvey(startRepeateSurveyObject) {
    const response = await apiHshClient.post(
      `/surveys/instances/repeatstart`,
      // `/surveys/instances/${surveyId}/start`,
      startRepeateSurveyObject
    );
    return response.data;
  },
  async fetchSurveysInstances() {
    const response = await apiHshClient.get(`/surveys/instances`);
    return response.data;
  },
  fetchReport(instanceId, question, filterArray) {
    console.log("fetchReport", instanceId, question, filterArray);
    // const response = await apiHshClient.get(`/surveys/instances/reports?report_type=bar-graph&question_id=${question.question_id}&sequence_nbr=${question.sequence_nbr}&survey_instance_ids=${instanceId}`);
    // return response.data;
    if (filterArray.length > 0) {
      let filtersStr = "";
      for (let i = 0; i < filterArray.length; i++) {
        filtersStr += `&filters=${filterArray[i]}`;
      }
      return apiHshClient.get(`/surveys/instances/reports/bargraphs/${instanceId}?${filtersStr}`);
    } else {
      return apiHshClient.get(`/surveys/instances/reports/bargraphs/${instanceId}`);
    }
  },
  async getSurveyInstanceCvs(surveyInstance) {
    const response = await apiHshClient.get(
      `/surveys/instances/reports/csv/${surveyInstance}`
    );
    return response;
  },
  ChangeSurveyInstanceTimes(instanceId, startDate, endDate) {
    return apiHshClient.put(`/surveys/instances/${instanceId}`, {
      starts_at: startDate,
      ends_at: endDate,
    });
  },
  async deleteSurveyInstance(instanceId) {
    const response = await apiHshClient.delete(`/surveys/instances/${instanceId}`);
    return response.data;
  },
  // =================================================
  // SurveyInstance and respondents
  // =================================================
  AddUserToSurveyInstans(surveyId, rid, payload) {
    return apiHshClient.post(
      `/surveys/instances/${surveyId}/respondents/${rid}`,
      payload
    );
  },
  // =================================================
  // Respondents
  // =================================================
  async getResponders() {
    const response = await apiHshClient.get("/respondents");
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async getSurveInstanceResponders(surveInstance) {
    const response = await apiHshClient.get(
      `surveys/instances/${surveInstance}/respondents`
    );
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async delSurveInstanceResponders(surveInstance, respondent_id) {
    const response = await apiHshClient.delete(
      `surveys/instances/${surveInstance}/${respondent_id}`
    );
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async createResponder(responder) {
    const obj = _.omit(responder, ["id"]);
    if (obj.terminal_id == "") {
      obj.terminal_id = null;
    }
    const response = await apiHshClient.post("/respondents", obj);
    return response.data;
  },
  async updateResponder(responder) {
    if (responder.terminal_id == "") {
      responder.terminal_id = null;
    }
    const response = await apiHshClient.put(
      `/respondents/${responder.id}`,
      responder
    );
    return response.data;
  },
  async updateResponderJwt(responderId) {
    const response = await apiHshClient.put(`/respondents/${responderId}/jwt`);
    return response.data;
  },
  async deleteResponder(responder) {
    const response = await apiHshClient.delete(`/respondents/${responder.id}`);
    return response.data;
  },
  // =================
  // Documents
  // =================
  async getQuestionDocument() {
    const response = await apiHshClient.get(`/questions/htmldoc`);
    return response;
  },
  async getQuestionPdfDocument(file) {
    const response = await apiHshClient.get(`/docs/pdf/${file}`);
    return response;
  },

  // =================
  // Settings
  // =================
  async respondentsSettings(responder) {
    const response = await apiHshClient.get(
      `/respondents/settings/${responder}`
    );
    return response.data;
  },
  storeSettingsAdm(uuid, userdata) {
    const storeObj = {
      respkey: uuid,
      attributes: userdata,
    };
    console.log("storeSettings store:", storeObj);
    return apiHshClient.post(`/respondents/settings`, storeObj);
  },
  // Respondents registration
  accountApplication() {
    const p = apiHshClient.get(`/respondents/userapplications`);
    return p;
  },
  grantApplicant(user) {
    const p = apiHshClient.put(
      `/respondents/userapplications/accept/${user.id}`,
      user
    );
    return p;
  },
  rejectApplicant(user) {
    const p = apiHshClient.delete(
      `/respondents/userapplications/reject/${user.id}`
    );
    return p;
  },
  // =================================================
  // Organisation
  // =================================================
  async getOrganisations(orgid) {
    const response = await apiHshClient.get(`/organisations/${orgid}`);
    return response.data;
  },
  async getFilterAttributes(org) {
    const response = await apiHshClient.get(
      `/respondents/filters/attributes/${org}`
    );
    return response.data;
  },

  async getFilters(org) {
    const response = await apiHshClient.get(`/respondents/filters/${org}`);
    return response.data;
  },
  async updateFilter(org, filter) {
    const response = await apiHshClient.put(
      `/respondents/filters/${org}/${filter.name}`,
      filter
    );
    return response;
  },
  async deleteFilter(org, filter) {
    const response = await apiHshClient.delete(
      `/respondents/filters/${org}/${filter.name}`,
      filter
    );
    return response;
  },
  async createFilter(org, filter) {
    filter.owner = org;
    const response = await apiHshClient.post(`/respondents/filters`, filter);
    return response;
  },
  async runFilter(filtername) {
    const response = await apiHshClient.get(
      `/respondents/listbyfilter/${filtername}`
    );
    return response.data;
  },
  async createFilterAttribute(filterAttribute) {
    const response = await apiHshClient.post(
      `/respondents/filters/attributes`,
      filterAttribute
    );
    return response.data;
  },
  async updateFilterAttribute(org, filterAttribute) {
    const response = await apiHshClient.put(
      `/respondents/filters/attributes/${org}/${filterAttribute.name}`,
      filterAttribute
    );
    return response.data;
  },
  async deleteFilterAttribute(org, filterAttribute) {
    const response = await apiHshClient.delete(
      `/respondents/filters/attributes/${org}/${filterAttribute.name}`,
      filterAttribute
    );
    return response.data;
  },
  async countMatchFilters(filterString) {
    const response = await apiHshClient.get(
      `/respondents/countbyfilter/${filterString}`
    );
    return response.data;
  },

  // =================================================
  // Terminals
  // =================================================
  async getTerminals() {
    const response = await apiHshClient.get(`/terminals`);
    return response.data;
  },
  // =================================================
  // Messages
  // =================================================

  async createMessage(message) {
    const response = await apiHshClient.post(`/messages`, message);
    return response.data;
  },
  async getMessages() {
    const response = await apiHshClient.get(`/messages`);
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async getMessage(id) {
    const response = await apiHshClient.get(`/messages/${id}`);
    if (response.data.results) {
      return response.data.results;
    }
    return response.data;
  },
  async revokeMessage(id) {
    const response = await apiHshClient.put(`/messages/revoke/${id}`);
    return response.data;
  },
  systemAckMessage(uuid, message_id, ack_msg) {
    const response = apiHshClient.put(`/messages/system_ack`, {
      respkey: uuid,
      message_id: message_id,
      ack_msg: ack_msg,
    });
    return response.data;
  },
};

export default hshApi;
