<template>
  <div>
    <div v-if="!hide">
      <v-icon @click="toggleDataTable"
        >mdi-arrow-down-drop-circle-outline</v-icon
      >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        sort-by="name"
        class="elevation-1 whitespace"
        :search="search"
        dense
        :single-select="true"
        :items-per-page="10"
        :otions.sync="pagination"
        :loading="!loadingStatus"
        loading-text="Laddar..."
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Rader per sida',
          'items-per-page-options': [3, 5, 10, -1],
          'items-per-page-all-text': 'Alla',
        }"
        @click:row="handleRowClick"
        :key="update"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editFilterItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Filter</v-toolbar-title>
            <v-spacer></v-spacer> -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                formulär
              </v-btn>
            </template>

            <v-dialog v-model="dialogDelete" max-width="600px">
              <!-- <template v-slot:item="{ item }"> -->
              <v-card>
                <v-card-title class="text-h5">
                  Vill du verkligen ta bort filter '{{ deleteCandidate.name }}'?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete()"
                    >Avbryt</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <!-- </template> -->
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:footer.prepend>
          <v-btn class="pa-4 ma-4" @click="createFilterItem()" color="primary">
            Skapa nytt filter
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök filter"
            single-line
            hide-details
            class="mb-5"
            v-on:keyup="clearOnEscape"
          ></v-text-field>
          <v-spacer></v-spacer>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-icon @click="toggleDataTable"
        >mdi-arrow-right-drop-circle-outline</v-icon
      >
      expandera listan...
    </div>
  </div>
</template>

<script>
import hshApi from "../services/hshapi";
import EditFilter from "./EditFilter.vue";

export default {
  name: "FilterTable",
  components: {},
  props: [],
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      hide: false,
      // bus: new Vue(),
      headers: [
        {
          text: "Filternamn",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Uttryck  ", value: "expression" },
        { text: "Hantera", value: "actions", sortable: false },
      ],
      items: [],
      editedIndex: -1,
      deleteCandidate: {},
      formItem: {},
      defaultFormItem: {},
      selected: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      update: 0,
    };
  },
  computed: {
    loadingStatus() {
      console.log(
        `TableForm: loadingStatus(${this.$store.getters.getLoadingStatus}) `
      );
      return this.$store.getters.getLoadingStatus == 0;
    },
  },
  async beforeMount() {
    // this.formItem = {
    //   ...this.$templates.form,
    //   nrOfForms: 0,
    // };
    // this.defaultFormItem = this.formItem;
    // await this.$store.dispatch("fetchForms", this.formItem.id);
    this.$root.$off("filtertable-form-toggle-hide");
    this.$root.$on("filtertable-form-toggle-hide", () => {
      console.log("Got hide toggle event");
      this.hide = !this.hide;
    });
    this.$log.info("Filtertable beforeMount");
  },
  mounted() {
    this.focusCurrentFormId();
    this.fetchFilter();
  },
  methods: {
    clearOnEscape(event) {
      //console.log("Keypressed:" + event.target.value + " Char " + event.key )
      if (event.key === "Escape") {
        this.search = "";
      }
    },
    focusCurrentFormId() {
      const item = this.$store.getters.getCurrentForm;
      if (item != null) {
        this.focusFilterForm(item);
      }
    },
    focusFilterForm(item) {
      console.log("focusFilterForm", item);
      this.$root.$emit("filtertable-form-clicked", item);
      this.selected = [];
      this.selected.push(item);
      // this.jumpToSelection();
    },
    jumpToSelection: function () {
      this.$nextTick(() => {
        let selected = this.selected[0];
        let page = Math.ceil(
          (this.items.indexOf(selected) + 1) / this.pagination.rowsPerPage
        );
        this.pagination.sortBy = "id"; // This routine doesn't exits...
        this.$nextTick(() => {
          this.pagination.page = page;
        });
      });
    },
    handleRowClick(item) {
      console.log("Filtertable: You clicked " + item.name);
      console.log(this.selected);
    },
    async createFilterItem() {
      console.log("createFilterItem");
      const res = await this.$dialog.show(EditFilter, {
        waitForResult: true,
        width: "90%",
        title: "Nytt filter",
        $store: this.$store,
        type: "create",
        item: { name: "", expression: "" },
      }); // Show dialog
      console.log("createFilterItem gave dialog res", res);
      try {
        await this.fetchFilter();
      } catch (err) {
        console.error("fetchfilter failed", err);
      }
      this.update++;
      this.$forceUpdate()
    },
    async editFilterItem(item) {
      console.log("editFilterItem");
      const res = await this.$dialog.show(EditFilter, {
        waitForResult: true,
        width: "90%",
        title: "Ändra filter",
        $store: this.$store,
        type: "edit",
        item: item,
      }); // Show dialog
      console.log("editFilterItem gave dialog res", res);
      await this.fetchFilter();
      this.update++;
    },

    deleteItem(item) {
      console.log("deleteItem", item);
      this.editedIndex = this.items.indexOf(item);
      this.deleteCandidate = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      //   deleteItem(item) {
      // Delete the item from the list
      const item = this.deleteCandidate;
      console.log("deletedItemConfirm");
      const index = this.items.indexOf(item);
      if (index < 0) {
        console.error("deletedItemConfirm index -1", index);
        return;
      }
      this.items.splice(index, 1);
      try {
        const res = await hshApi.deleteFilter(
          this.$keycloak.tokenParsed.organisation,
          item
        );
        console.log("FilterTable: deleteItemConfirm", res);
        this.$store.state.applStore.hasUnsavedChanges = false;
      } catch (error) {
        await this.$dialog.error({
          text: `Borttagningen misslyckades (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    itemRowBackground(item) {
      if (this.selected.length <= 0) {
        return "style-2";
      }
      console.log(
        "itemRowBackground Filtertable called " +
          item.id +
          " " +
          this.selected[0].id
      );
      return item.id == this.selected[0].id ? "style-1" : "style-2";
    },
    toggleDataTable() {
      this.$root.$emit("filtertable-form-toggle-hide");
    },

    async fetchFilter() {
      console.log("Filter: fetchFilter  ");
      try {
        const res = await hshApi.getFilters(
          this.$keycloak.tokenParsed.organisation
        );
        this.items = res;
        this.$store.state.applStore.hasUnsavedChanges = false;
      } catch (error) {
        await this.$dialog.error({
          text: `Filtervärden gick inte att hämta (${error.message} - ${error.response.data})`,
          title: `Varning!`,
          actions: {
            ok: {
              text: "OK",
              color: "blue darken-1",
              handle: () => {
                return undefined;
              },
            },
          },
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.$store.state.applStore.hasUnsavedChanges) {
        const answer = window.confirm(
          "Du har osparade ändringar. Vill du verkligen byta sida?"
        );
        if (answer) {
          this.$store.state.applStore.hasUnsavedChanges = false;
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    },
  },
};
</script>

<style scoped>
.style-1 {
  background-color: rgb(53, 32, 146) !important;
}
.style-2 {
  background-color: rgb(114, 114, 67) !important;
}
</style>
