var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.hide
      ? _c(
          "div",
          [
            _c("v-icon", { on: { click: _vm.toggleDataTable } }, [
              _vm._v("mdi-arrow-down-drop-circle-outline")
            ]),
            _c("v-data-table", {
              key: _vm.update,
              staticClass: "elevation-1 whitespace",
              attrs: {
                headers: _vm.headers,
                items: _vm.items,
                "sort-by": "name",
                search: _vm.search,
                dense: "",
                "single-select": true,
                "items-per-page": 10,
                otions: _vm.pagination,
                loading: !_vm.loadingStatus,
                "loading-text": "Laddar...",
                "footer-props": {
                  showFirstLastPage: true,
                  "items-per-page-text": "Rader per sida",
                  "items-per-page-options": [3, 5, 10, -1],
                  "items-per-page-all-text": "Alla"
                }
              },
              on: {
                "update:otions": function($event) {
                  _vm.pagination = $event
                },
                "click:row": _vm.handleRowClick
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.editFilterItem(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-pencil ")]
                        ),
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-delete ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-toolbar",
                          {
                            attrs: { flat: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                color: "primary",
                                                dark: ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" formulär ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2884478123
                            )
                          },
                          [
                            _c("v-toolbar-title", [_vm._v("Filter")]),
                            _c("v-spacer"),
                            _vm._v(" --> "),
                            _c(
                              "v-dialog",
                              {
                                attrs: { "max-width": "600px" },
                                model: {
                                  value: _vm.dialogDelete,
                                  callback: function($$v) {
                                    _vm.dialogDelete = $$v
                                  },
                                  expression: "dialogDelete"
                                }
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-title",
                                      { staticClass: "text-h5" },
                                      [
                                        _vm._v(
                                          " Vill du verkligen ta bort filter '" +
                                            _vm._s(_vm.deleteCandidate.name) +
                                            "'? "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "blue darken-1",
                                              text: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.closeDelete()
                                              }
                                            }
                                          },
                                          [_vm._v("Avbryt")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "blue darken-1",
                                              text: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteItemConfirm()
                                              }
                                            }
                                          },
                                          [_vm._v("OK")]
                                        ),
                                        _c("v-spacer")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "footer.prepend",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "pa-4 ma-4",
                            attrs: { color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.createFilterItem()
                              }
                            }
                          },
                          [_vm._v(" Skapa nytt filter ")]
                        ),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          staticClass: "mb-5",
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Sök filter",
                            "single-line": "",
                            "hide-details": ""
                          },
                          on: { keyup: _vm.clearOnEscape },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        }),
                        _c("v-spacer")
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2963926281
              ),
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("v-icon", { on: { click: _vm.toggleDataTable } }, [
              _vm._v("mdi-arrow-right-drop-circle-outline")
            ]),
            _vm._v(" expandera listan... ")
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }